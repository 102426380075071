import { observable, action, decorate } from 'mobx';

import LogsStore from './Logs';
import TokBoxStore from './TokBoxStore';
import AuthStore from './Auth';
import SettingsStore from './Settings';
import ControlTableStore from './ControlTable';
import CallsStats from './CallsStats';
import Socket from '../sockets/Socket';

import API from '../services/api';

class RootStore {
  constructor() {
    this.socket = new Socket(this);
    this.logsStore = new LogsStore(this);
    this.tokBoxStore = new TokBoxStore(this);
    this.authStore = new AuthStore(this);
    this.settingsStore = new SettingsStore(this);
    this.callsStats = new CallsStats(this);
    this.controlTableStore = new ControlTableStore(this);
  }

  isLoading = true;

  isCollapsed = false;

  makeRequest = async (type, url, params) => {
    this.isLoading = true;
    try {
      const res = await API[type](url, params);
      return res;
    } catch ({ response }) {
      if (!response) return null;

      if (response.status === 401 || response.status === 403) {
        this.authStore.logOutUser();
        return response;
      }
    } finally {
      this.isLoading = false;
    }
  };

  getInitDataAndSubscribe = () => {
    // subscriptions here
    this.authStore.subscribe();
    this.callsStats.subscribe();
    this.controlTableStore.subscribe();
    this.logsStore.subscribe();
    this.tokBoxStore.subscribe();

    // get initial data here
    this.controlTableStore.getInitialData();
    this.callsStats.getInitialCallsStats();
    this.logsStore.getInitialData();
  };
}

export default decorate(RootStore, {
  isLoading: observable,
  isCollapsed: observable,
  makeRequest: action,
  getInitDataAndSubscribe: action,
});
