import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { observer } from 'mobx-react';
import './styles.less';

import { useRootStore } from '../../store/RootStateContext';

const StatusSecurity = () => {
  const { authStore } = useRootStore();

  const primarySecurityName = authStore.primarySecurity.name
    ? authStore.primarySecurity.name
    : '-';

  const info = (
    <>
      <FormattedMessage id="statusSecutiry.primary" />:{' '}
      {authStore.isLoading ? <LoadingOutlined spin /> : primarySecurityName}
    </>
  );

  const infoWithLink = (data) => {
    return <Link to="/app/settings">{data}</Link>;
  };

  return (
    <div className="statusSecurity">
      {authStore.isSecurity ? infoWithLink(info) : info}
    </div>
  );
};

export default observer(StatusSecurity);
