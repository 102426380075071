import { observable, decorate, action, computed } from 'mobx';
import { notification } from 'antd';
import OT from '@opentok/client';

class TokBoxStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  currentConnection = null;

  incomingCallsList = [];

  incomingCallData = null;

  secondConnection = null;

  isChangeSession = false;

  incomingCallTimeout = null;

  // OpenTok(TokBox) variables and init data --- start

  session = null;

  publisherProps = {
    publishAudio: true,
    publishVideo: false,
    insertDefaultUI: false,
    name: 'STREAM_SECURITY',
    style: {
      archiveStatusDisplayMode: 'off',
      nameDisplayMode: 'off',
    },
  };

  subscriberProps = {
    insertMode: 'replace',
    width: '100%',
    height: '100%',
    style: {
      nameDisplayMode: 'off',
      buttonDisplayMode: 'off',
    },
  };

  publisher = null;

  subscriber = null;

  isReconnecting = false;

  // OpenTok(TokBox) variables and init data --- end

  get isIncoming() {
    return !!(this.incomingCallData && !this.currentConnection);
  }

  get isStreamActive() {
    return !!(
      this.currentConnection && this.currentConnection.callState === 'connected'
    );
  }

  get incomingCallImage() {
    let img = 'none';

    if (this.incomingCallData && this.incomingCallData.data) {
      img = this.incomingCallData.data.caller_images.image;
    }

    return img;
  }

  // General Call Management

  setCallData = (data) => {
    this.currentConnection = {
      ...this.currentConnection,
      apiKey: data.api_key,
      sessionId: data.session_id,
      callToken: data.token,
    };

    this.startOpenTokSession();
  };

  startOpenTokSession = () => {
    const systemReq = OT.checkSystemRequirements();

    if (systemReq !== 1) {
      // eslint-disable-next-line no-console
      console.error(
        `OpenTok reports being unable to run (error code: ${systemReq})`
      );
      return;
    }

    try {
      this.session = OT.initSession(
        this.currentConnection.apiKey,
        this.currentConnection.sessionId
      );

      this.publisher = OT.initPublisher('publisher', this.publisherProps);

      this.subscriber = this.session.on('streamCreated', (event) => {
        this.currentConnection.callState = 'connected';
        this.currentConnection.showSpinner = false;

        this.session.subscribe(
          event.stream,
          'subscriber',
          this.subscriberProps,
          // eslint-disable-next-line no-unused-vars
          (e) => {}
        );
      });

      this.session.connect(this.currentConnection.callToken, (e) => {
        if (e) {
          // eslint-disable-next-line no-console
          console.error(`error while connect to session: ${e}`);
        } else {
          this.session.publish(this.publisher);
        }
      });

      this.session.on('sessionReconnecting', () => {
        this.isReconnecting = true;
        // eslint-disable-next-line no-console
        console.info('Session Reconnecting');
      });

      this.session.on('sessionReconnected', () => {
        this.isReconnecting = false;
        // eslint-disable-next-line no-console
        console.info('Session Reconnected');
      });

      this.session.on('streamDestroyed', () => {
        this.isReconnecting = false;
        // eslint-disable-next-line no-console
        console.info('Stream Destroyed');
        this.closeCall(this.currentConnection.currentVuId, 'connection_lost');
      });

      this.session.on('sessionDisconnected', (event) => {
        this.isReconnecting = false;
        if (event.reason === 'networkDisconnected') {
          // eslint-disable-next-line no-console
          console.info('OpenTok session disconnected due to network issues');
        } else if (event.reason === 'clientDisconnected') {
          // eslint-disable-next-line no-console
          console.info('OpenTok session disconnected');
        } else {
          // eslint-disable-next-line no-console
          console.info('Session disconnected for unknown reason');
        }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  setIncomingCallData = (incomingCallData) => {
    if (!this.incomingCallData && !this.currentConnection) {
      this.incomingCallData = incomingCallData;
    } else {
      this.incomingCallsList.push(incomingCallData);
    }

    this.incomingCallTimeout = setTimeout(() => {
      if (this.isIncoming) {
        this.onUnansweredCall();
      }
    }, 30000);
  };

  isCallResponded = (data) => {
    if (this.incomingCallsList) {
      const hasNots = this.incomingCallsList.find(
        (call) => call.device_id === data.device_id
      );

      if (hasNots) {
        notification.close(hasNots.data.call_id);
        this.incomingCallsList = this.incomingCallsList.filter(
          (call) => call.device_id !== data.device_id
        );
      }
    }

    if (
      this.incomingCallData &&
      this.incomingCallData.device_id === data.device_id
    ) {
      if (this.incomingCallTimeout) {
        clearTimeout(this.incomingCallTimeout);
        this.incomingCallTimeout = null;
      }
      this.incomingCallData = null;
    }
  };

  startCall = (id, deviceName) => {
    if (!this.currentConnection) {
      this.rootStore.socket.send({
        activity: 'action_start_connect',
        visitor_unit: id,
      });
      this.currentConnection = {
        currentVuId: id,
        device_name: deviceName,
        callState: 'connecting',
        showSpinner: true,
        isIncomingCall: false,
      };
    } else if (
      this.currentConnection &&
      this.currentConnection.currentVuId !== id
    ) {
      this.isChangeSession = true;
      this.secondConnection = id;
    } else {
      this.currentConnection = null;
      // eslint-disable-next-line no-console
      console.log('line is busy');
    }
  };

  closeCall = (id, reason = 'closed_by_security') => {
    if (this.currentConnection) {
      this.currentConnection.showSpinner = true;
      this.currentConnection.callState = 'disconnecting';
    }
    this.rootStore.socket.send({
      activity: 'action_close_connect',
      visitor_unit: id,
      reason,
    });
    if (this.session) {
      this.session.off();
      this.session.disconnect();
      this.session.unpublish(this.publisher);
      this.publisher.destroy();
      this.session.unsubscribe(this.subscriber);
    }
  };

  changeCall = (deviceName) => {
    if (this.incomingCallsList) {
      this.closeAllCallNotification();
    }

    this.isChangeSession = false;
    this.publisher.destroy();
    this.session.disconnect();
    this.closeCall(this.currentConnection.currentVuId);
    this.clearSession(this.currentConnection.currentVuId);
    this.startCall(this.secondConnection, deviceName);
    this.secondConnection = null;
  };

  clearSession = () => {
    if (this.currentConnection) {
      this.currentConnection = null;
      this.session = null;
    }
  };

  // Open Door btn Managemet

  callOpenDoor = (id, isIncomingCall) => {
    const current_call_id = this.isIncoming
      ? this.incomingCallData.data.call_id
      : this.currentConnection.call_id;

    const activity = isIncomingCall
      ? 'action_call_door_open'
      : 'action_security_connect_door_open';

    this.rootStore.socket.send({
      activity,
      visitor_unit: id,
      call_id: current_call_id,
    });

    if (this.isIncoming) {
      this.incomingCallData = null;
    }
  };

  // Incoming Call Management

  acceptIncomingCall = () => {
    this.rootStore.socket.send({
      activity: 'action_call_accepted',
      visitor_unit: this.incomingCallData.device_id,
      call_id: this.incomingCallData.data.call_id,
    });

    this.currentConnection = {
      apiKey: this.incomingCallData.data.tokbox_session.api_key,
      sessionId: this.incomingCallData.data.tokbox_session.session_id,
      callToken: this.incomingCallData.data.tokbox_session.token,
      currentVuId: this.incomingCallData.device_id,
      call_id: this.incomingCallData.data.call_id,
      device_name: this.incomingCallData.data.device_name,
      callState: 'connecting',
      showSpinner: true,
      isIncomingCall: true,
    };

    this.incomingCallData = null;

    clearTimeout(this.incomingCallTimeout);
    this.incomingCallTimeout = null;

    this.closeAllCallNotification();

    this.startOpenTokSession();
  };

  onUnansweredCall = () => {
    this.rootStore.socket.send({
      activity: 'action_call_unanswered',
      visitor_unit: this.incomingCallData.device_id,
      data: this.incomingCallData,
    });
    this.incomingCallData = null;
  };

  rejectIncomingCall = () => {
    if (this.currentConnection) {
      this.currentConnection.showSpinner = true;
      this.currentConnection.callState = 'disconnecting';
    }

    const current_vu_id = this.isIncoming
      ? this.incomingCallData.device_id
      : this.currentConnection.currentVuId;

    const current_call_id = this.isIncoming
      ? this.incomingCallData.data.call_id
      : this.currentConnection.call_id;

    this.rootStore.socket.send({
      activity: 'action_call_rejected',
      visitor_unit: current_vu_id,
      call_id: current_call_id,
    });

    if (this.incomingCallData) {
      this.incomingCallData = null;
    }

    if (this.currentConnection) {
      this.session.off();
      this.session.disconnect();
      this.session.unpublish(this.publisher);
      this.publisher.destroy();
      this.session.unsubscribe(this.subscriber);
    }

    clearTimeout(this.incomingCallTimeout);
    this.incomingCallTimeout = null;
  };

  isConnectFailed = () => {
    if (this.session) {
      this.session.off();
      this.session.disconnect();
      this.session.unpublish(this.publisher);
      this.publisher.destroy();
      this.session.unsubscribe(this.subscriber);
      this.session = null;
    }

    this.currentConnection = null;
  };

  isCallCanceled = (data) => {
    if (
      this.incomingCallData &&
      this.incomingCallData.device_id === data.device_id
    ) {
      this.rejectIncomingCall();
    }

    if (
      this.currentConnection &&
      this.currentConnection.currentVuId === data.device_id
    ) {
      if (data && data.event_type === 34) {
        this.closeCall(data.device_id, 'connection_lost');
      }
      this.closeCall(data.device_id);
    }

    if (this.incomingCallsList.length > 0) {
      const canceledCall = this.incomingCallsList.filter(
        (call) => call.data.device_id === data.device_id
      );

      if (canceledCall.length > 0) {
        notification.close(canceledCall.data.call_id);
        this.incomingCallsList = this.incomingCallsList.filter(
          (call) => call.data.device_id !== data.device_id
        );
      }
    }
  };

  // Call Notification Management

  closeAllCallNotification = () => {
    if (this.incomingCallsList) {
      this.incomingCallsList.forEach((el) => {
        notification.close(el.data.call_id);
        if (this.currentConnection.currentVuId !== el.device_id) {
          this.rootStore.socket.send({
            activity: 'action_call_rejected',
            visitor_unit: el.device_id,
            call_id: el.data.call_id,
          });
        }
      });

      this.incomingCallsList = [];
    }
  };

  acceptNotificationCall = (el) => {
    if (this.currentConnection) {
      this.rejectIncomingCall();
    }

    if (this.incomingCallData) {
      this.rootStore.socket.send({
        activity: 'action_call_rejected',
        visitor_unit: this.incomingCallData.device_id,
        call_id: this.incomingCallData.data.call_id,
      });
      this.incomingCallData = null;
    }

    this.rootStore.socket.send({
      activity: 'action_call_accepted',
      visitor_unit: el.device_id,
      call_id: el.data.call_id,
    });

    this.currentConnection = {
      apiKey: el.data.tokbox_session.api_key,
      sessionId: el.data.tokbox_session.session_id,
      callToken: el.data.tokbox_session.token,
      currentVuId: el.device_id,
      call_id: el.data.call_id,
      device_name: el.data.device_name,
      callState: 'connecting',
      showSpinner: true,
      isIncomingCall: true,
    };

    clearTimeout(this.incomingCallTimeout);
    this.incomingCallTimeout = null;

    this.closeAllCallNotification();

    this.startOpenTokSession();
  };

  denyNotificationCall = (el) => {
    notification.close(el.data.call_id);
    this.rootStore.socket.send({
      activity: 'action_call_rejected',
      visitor_unit: el.device_id,
      call_id: el.data.call_id,
    });

    this.incomingCallsList = this.incomingCallsList.filter(
      (call) => call.data.call_id !== el.data.call_id
    );
  };

  unansweredNotificationCallCountdown = (el) => {
    setTimeout(() => {
      if (
        this.incomingCallsList.filter(
          (call) => call.data.call_id === el.data.call_id
        ).length > 0
      ) {
        notification.close(el.data.call_id);
        this.rootStore.socket.send({
          activity: 'action_call_unanswered',
          visitor_unit: el.device_id,
          data: el,
        });

        this.incomingCallsList = this.incomingCallsList.filter(
          (call) => call.data.call_id !== el.data.call_id
        );
      }
    }, 15000);
  };

  // Subscriptions Management

  subscribe = () => {
    this.rootStore.socket.subscribe('start_connect', ({ data }) =>
      this.setCallData(data)
    );
    this.rootStore.socket.subscribe('call_to_security', (data) =>
      this.setIncomingCallData(data)
    );
    this.rootStore.socket.subscribe('call_responded', ({ data }) =>
      this.isCallResponded(data)
    );
    this.rootStore.socket.subscribe('call_canceled', ({ data }) =>
      this.isCallCanceled(data)
    );
    this.rootStore.socket.subscribe('connect_failed', ({ data }) =>
      this.isConnectFailed(data)
    );
  };
}

export default decorate(TokBoxStore, {
  session: observable,
  currentConnection: observable,
  isChangeSession: observable,
  secondConnection: observable,
  incomingCallData: observable,
  incomingCallsList: observable,
  publisherProps: observable,
  subscriberProps: observable,
  publisher: observable,
  subscriber: observable,
  incomingCallTimeout: observable,
  isReconnecting: observable,
  incomingCallImage: computed,
  setIncomingCallData: action,
  isIncoming: computed,
  isStreamActive: computed,
  subscribe: action,
  denyNotificationCall: action,
  acceptNotificationCall: action,
  closeAllCallNotification: action,
  unansweredNotificationCallCountdown: action,
  startCall: action,
  setCallData: action,
  startOpenTokSession: action,
  acceptIncomingCall: action,
  onUnansweredCall: action,
  callOpenDoor: action,
  isCallResponded: action,
  isCallCanceled: action,
  changeCall: action,
  rejectIncomingCall: action,
  closeCall: action,
  isConnectFailed: action,
  clearSession: action,
});
