import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Badge, Drawer } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from '../../store/RootStateContext';
import Modal from '../Modal';
import LogItem from '../LogItem';

import './styles.less';

const NotificationDrawer = () => {
  const { logsStore } = useRootStore();
  const [isModalOpen, setModalIsOpen] = useState(false);

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const getId = () => nanoid();

  const toggleModal = (id) => () => {
    if (!isModalOpen && logsStore.selectedNotification.data.event_id !== id) {
      setVisible(false);
      logsStore.setSelectedNotification(id);
    }
    setModalIsOpen(!isModalOpen);
  };

  const handleDelete = () => {
    logsStore.deleteNotification(logsStore.selectedNotification.data.event_id);
    setModalIsOpen(!isModalOpen);
  };

  const notes = logsStore.notifications.length ? (
    <div className="notification-list-wrapper">
      <div className="notifications-controls-wrapper">
        <button
          type="button"
          className="notifications-list-clear-btn"
          onClick={logsStore.deleteAllNotifications}
          disabled={!logsStore.notifications.length}
        >
          <span className="notifications-clear-btn-title">
            <FormattedMessage id="notificationDrawer.acknowledgeAll" />
          </span>
        </button>
      </div>
      {logsStore.notifications.map((item) => (
        <LogItem
          key={getId()}
          item={item}
          toggleModal={toggleModal(item.data.event_id)}
          isNotification
        />
      ))}
    </div>
  ) : (
    <div className="notification-list-wrapper">
      <FormattedMessage id="notificationDrawer.noNewNotifications" />
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          title={
            <FormattedMessage
              id="notificationDrawer.visitorUnit"
              values={{ name: logsStore.selectedNotification.data.device_name }}
            />
          }
          okBtnTxt={<FormattedMessage id="notificationDrawer.gotIt" />}
          onOk={handleDelete}
          cancelBtnTxt={<FormattedMessage id="notificationDrawer.snooze" />}
          onCancel={toggleModal(logsStore.selectedNotification.data.event_id)}
          isNotification
        >
          {`${logsStore.selectedNotification.data.details}.`}
        </Modal>
      )}

      <button type="button" className="notification-icon" onClick={showDrawer}>
        <Badge
          size="small"
          count={logsStore.notifications.length}
          overflowCount={99}
        >
          <BellOutlined className="bell-icon" />
        </Badge>
      </button>

      <Drawer
        className="sidedrawer-wrapper"
        title={<FormattedMessage id="notificationDrawer.notifications" />}
        placement="right"
        closable
        width={380}
        onClose={onClose}
        visible={visible}
      >
        {notes}
      </Drawer>
    </>
  );
};

export default observer(NotificationDrawer);
