import LOCALES from '../locales';

export default {
  [LOCALES.FRENCH]: {
    'menu.profile': 'Profil',
    'menu.setting': 'Réglage',
    'menu.logout': 'Déconnexion',
    'menu.dashboard': 'Tableau de bord',

    'statusSecutiry.primary': 'Sécurité principale',

    'callPlayer.openDoor': 'Porte Ouverte',
    'callPlayer.connectionLost': 'Connexion perdue!',
    'callPlayer.reconnecting': 'Reconnexion... ',
    'callPlayer.answerCall': "Répondre à l'Appel",
    'callPlayer.denyAccess': "Refuser l'Accès",
    'callPlayer.endCall': 'End Call',

    'callSettingsInfo.redirected': 'Redirigé vers:',
    'callSettingsInfo.rollover': 'Renvoi vers:',

    'confirmForm.msgEmptyPass': 'Veuillez saisir votre mot de passe!',
    'confirmForm.newPassword': 'Nouveau mot de passe',
    'confirmForm.msgEmptyPassConfirm': 'Veuillez confirmer votre mot de passe!',
    'confirmForm.repeatNewPassword': 'Répéter le nouveau mot de passe',
    'confirmForm.confirmPassword': 'Confirmer le mot de passe',
    'confirmForm.confirmScreen': 'Confirmation screen',

    'сontrolTableCall.msgModal':
      'Êtes-vous sûr de vouloir démarrer une nouvelle session? La session actuelle sera fermée!',
    'сontrolTableCall.warning': 'Attention!',
    'сontrolTableCall.connect': 'Connecter',
    'сontrolTableCall.cancel': 'Annuler',

    'controlTableConfig.good': 'bon',
    'controlTableConfig.open': 'ouvert',
    'controlTableConfig.closed': 'fermé',
    'controlTableConfig.locked': 'verrouillé',
    'controlTableConfig.unlocked': 'déverrouillé',
    'controlTableConfig.lockdown': 'verrouillé',
    'controlTableConfig.disabled': 'désactivé',
    'controlTableConfig.NA': 'NA',
    'controlTableConfig.connected': 'connecté',
    'controlTableConfig.notConnected': 'non connecté',
    'controlTableConfig.activated': 'activé',

    'timerBtn.labelTimeDuration': 'Choisissez la durée (en minutes):',
    'timerBtn.labelCustomDuration':
      'Ou entrez la durée personnalisée (en minutes, max 480 min)',
    'timerBtn.selectOrEnterTime': 'Sélectionnez ou entrez la durée',
    'timerBtn.submit': 'Soumettre',
    'timerBtn.cancel': 'Annuler',

    'controlTableSettings.visitorUnit': 'UNITÉ VISITEUR',
    'controlTableSettings.lock': 'VERROUILLAGE',
    'controlTableSettings.door': 'PORTE',
    'controlTableSettings.doorControllerStatus':
      'STATUT DU CONTRÔLEUR DE PORTE',
    'controlTableSettings.networkStatus': 'STATUT DU RÉSEAU',
    'controlTableSettings.videoConnect': 'CONNEXION VIDÉO',
    'controlTableSettings.openDoor': 'PORTE OUVERTE',
    'controlTableSettings.unlock': 'DÉVERROUILLAGE',
    'controlTableSettings.timer': 'MINUTERIE',
    'controlTableSettings.disableVisiror': 'DÉSACTIVER LE VISITEUR',
    'controlTableSettings.lockdown': 'CONFINEMENT',

    'headerNavMenu.dashboard': 'Tableau de bord',

    'incomingCallNotification.incomingCallFrom':
      'Vous avez un appel entrant de',
    'incomingCallNotification.answer': 'Répondre',
    'incomingCallNotification.deny': 'Refuser',
    'incomingCallNotification.incomingCall': 'Appel Entrant',

    'logsSection.realTimeLogging': 'Enregistrement en temps réel',
    'logsSection.acknowledgeAll': 'Accuser réception de tous les appels',
    'logsSection.noData': 'Aucune donnée',

    'notificationDrawer.acknowledgeAll': 'Accuser réception de tous les appels',
    'notificationDrawer.noNewNotifications': 'Pas de nouvelles notifications',
    'notificationDrawer.visitorUnit': 'The Visitor Unit {name}',
    'notificationDrawer.gotIt': "J'ai trouvé",
    'notificationDrawer.snooze': 'Sourdine ',
    'notificationDrawer.notifications': 'Notifications',

    'passRecoveryForm.msgEmptyEmail': 'Veuillez saisir votre e-mail!',
    'passRecoveryForm.email': 'Courriel',
    'passRecoveryForm.recoveryScreen': 'Recovery screen',
    'passRecoveryForm.sendRequest': 'Envoyer la Demande',
    'passRecoveryForm.confimationRequestSent':
      'Demande de confirmation envoyée!',

    'profileSection.oldPassword': 'Ancien Mot de passe',
    'profileSection.newPassword': 'Nouveau mot de passe',
    'profileSection.msgEmptyPass': 'Veuillez saisir votre mot de passe!',
    'profileSection.newPassConfirmation':
      'Confirmation du Nouveau Mot de Passe',
    'profileSection.msgEmptyConfirmPass':
      'Veuillez confirmer votre mot de passe! ',
    'profileSection.profile': 'Profil ',
    'profileSection.changePass': 'Modifier le mot de passe',

    'settingsSection.settings': 'Paramètres',
    'settingsSection.primary': 'Sécurité principale',
    'settingsSection.redirectionFromVU':
      'Redirection des appels provenant des unités visiteur',
    'settingsSection.rolloverFromVU': 'Renvoi des appels des unités visiteur',
    'settingsSection.msgNoSelected':
      'Aucun utilisateur sélectionné pour la redirection et le retournement',
    'settingsSection.membersNotFound':
      'Members for redirect/rollover are not found',
    'settingsSection.apply': 'Appliquer',
    'settingsSection.noteDescription':
      'Note: Les paramètres de la dernière personne connectée sont sélectionnés comme primaires et cette personne recevra tous les appels redirigés ou transférés. Pour renouveler les paramètres, veuillez-vous connecter à nouveau.',
    'settingsSection.directCallsVUList': "Liste d'UV pour les Appels Directs",
    'settingsSection.VUname': "Nom de l'UV",
    'settingsSection.username': "Nom de l'utilisateur",

    'signInForm.logInScreen': 'Écran de connexion',
    'signInForm.msgEmptyEmail': 'Veuillez saisir votre e-mail!',
    'signInForm.email': 'Courriel',
    'signInForm.msgEmptyPass': 'Veuillez saisir votre mot de passe!',
    'signInForm.pass': 'Mot de passe',
    'signInForm.forgotPass': 'Mot de passe oublié',
    'signInForm.login': 'Se connecter',

    'errorPage.titleResult': 'Il y a des problèmes avec votre opération',

    'services.settingsChanges': 'Modification des paramètres',
    'services.settingsAppliedSuccessfully':
      'Les paramètres ont été appliqués avec succès.',
    'services.profileChanges': 'Changements de profil',
    'services.passChangedSuccessfully': 'Password changed successfully.',
    'services.passChangeError': 'Erreur de changement de mot de passe',
    'services.passRecoveryError': 'Erreur de récupération du mot de passe',
    'services.settingsChangeError': 'Erreur de modification des paramètres',
    'services.authorizationError': "Erreur d'autorisation",
    'services.msgIncorrectEmail':
      "Veuillez saisir l'adresse électronique et le mot de passe corrects pour un compte de sécurité. Notez que ces deux champs peuvent être sensibles à la casse.",
    'services.confirmationError': 'Erreur de confirmation',
    'services.unknownError': 'Erreur inconnue',
    'services.somethingWentWrong':
      "Une erreur s'est produite! (Code: {status})",
    'services.msgErrorMatchPass':
      'Les deux mots de passe que vous avez saisis ne correspondent pas!',
    'services.msgErrorPassValidation':
      'Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un caractère spécial et un chiffre, 1 caractère spécial, 1 chiffre.',
    'services.wrongEmail': 'Mauvais courriel!',

    'auth.email': 'Courriel',
    'auth.firstName': 'Prénom',
    'auth.lastName': 'Nom de famille',
    'auth.registrationDate': "Date d'Enregistrement",
    'auth.lastLoginDate': 'Date de la dernière connexion',

    'modal.isItYourShift': 'Est-ce votre quart de travail?',
    'modal.primaryIsChanged': 'La sécurité primaire est modifiée',
    'modal.primaryTurnedOff': 'Primary Security status has been turned off',
    'modal.withNameReturnPrimary':
      '{name} est maintenant Sécurité Primaire. Voulez-vous retourner au statut de la Sécurité Primaire?',
    'modal.returnPrimary': 'Do you want to return Primary Security status?',
    'modal.msgShiftModal':
      "Si c'est votre quart de travail, nous vous activerons en tant que Sécurité Principale et vos Paramètres seront appliqués.",
    'modal.yes': 'Oui',
    'modal.no': 'Non',
  },
};
