/* eslint-disable react/prop-types */
import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { observer } from 'mobx-react';

import { useRootStore } from './store/RootStateContext';
import { routes, routesWithoutSettings, authRoutes } from './routes';
import layouts from './layouts';
import { I18nProvider } from './i18n';
import DocumentHead from './components/DocumentHead/DocumentHead';

const App = ({ history }) => {
  const { authStore } = useRootStore();

  const renderRoutes = ({
    routesList,
    condition,
    redirectPath,
    layoutName,
  }) => {
    const Layout = layouts[layoutName];

    return routesList.map(({ Component, ...rest }) => (
      <Route
        key={rest.path}
        {...rest}
        render={({ location }) =>
          condition ? (
            <Layout>
              <Component />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: redirectPath,
                state: { from: location },
              }}
            />
          )
        }
      />
    ));
  };

  return (
    <I18nProvider locale={authStore.lang}>
      <>
       <DocumentHead />
      <Router history={history}>
        <Switch>
          {renderRoutes({
            routesList: authRoutes,
            condition: !authStore.authUser,
            redirectPath: '/app/dashboard',
            layoutName: 'AuthLayout',
          })}
          {renderRoutes({
            routesList: authStore.isSecurity ? routes : routesWithoutSettings,
            condition: authStore.authUser,
            redirectPath: '/signin',
            layoutName: 'MainLayout',
          })}
          <Route path="*">
            <Redirect to="/app/dashboard" />
          </Route>
        </Switch>
        </Router>
      </>
    </I18nProvider>
  );
};

export default observer(App);
