const URL = process.env.REACT_APP_WS_APP_URL;

class Socket {
  errorHandler = null;

  subscriptions = {};

  connection = null;

  timerId = 0;

  establishConnection = () => {
    this.connection = new WebSocket(URL);
    this.connection.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      const cb = this.subscriptions[eventData.type];

      if (cb) {
        cb(eventData);
      }
    };
  };

  send = (message) => {
    this.connection.send(JSON.stringify(message));
  };

  subscribe = (eventName, cb) => {
    this.subscriptions[eventName] = cb;
  };

  onOpen = (cb) => {
    this.connection.onopen = (event) => {
      // eslint-disable-next-line no-console
      console.log(
        'Connection Established. Code:',
        event.currentTarget.readyState
      );
      cb();
      this.keepAlive();
    };
  };

  keepAlive = () => {
    const timeout = 5000;

    if (!this.connection) return;

    if (this.connection.readyState === 1) {
      this.connection.send(JSON.stringify({ activity: 'action_healthcheck' }));
    }

    if (this.connection.readyState !== 1) {
      this.establishConnection();
    }

    this.timerId = setTimeout(this.keepAlive, timeout);
  };

  cancelKeepAlive = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  };

  closeConnection = () => {
    if (this.connection && this.connection.readyState === 1) {
      this.connection.close();
      this.connection.onclose = (event) => {
        // eslint-disable-next-line no-console
        console.log('Connection Closed! Code:', event.currentTarget.readyState);
      };
    }
    this.subscriptions = {};
    this.connection = null;
  };
}

export default Socket;
