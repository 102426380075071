import React from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import { LoadingOutlined } from '@ant-design/icons';
import { useRootStore } from '../../store/RootStateContext';
import s from './CompanyList.module.css';

const { Option } = Select;

// eslint-disable-next-line react/prop-types
const CompanyList = () => {
  const { controlTableStore, authStore, logsStore } = useRootStore();

  const { companies, selectedCompany } = controlTableStore;

  const handleChange = (id) => {
    authStore.resetSecurityData();
    logsStore.clearLogs();
    controlTableStore.setNewCompany(id);
    controlTableStore.isCompanyLoading = true;
  };

  const loading = () => {
    const loader = <LoadingOutlined spin className={s.loader} />;
    return controlTableStore.isCompanyLoading ? loader : null;
  };

  if (!companies.length)
    return (
      <div style={{ width: 280, color: '#ffffff' }}>
        <LoadingOutlined spin />
      </div>
    );

  return (
    <Select
      value={selectedCompany}
      style={{ width: 280 }}
      onChange={handleChange}
      disabled={controlTableStore.isCompanyLoading}
    >
      {companies.map((company) => (
        <Option value={company.id} key={company.id}>
          {company.name}
          {loading()}
        </Option>
      ))}
    </Select>
  );
};

export default observer(CompanyList);
