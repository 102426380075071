import { observable, action, decorate } from 'mobx';
import showNotificationToster from '../services/showNotification';

class LogsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  logs = [];

  notifications = [];

  selectedNotification = {
    id: null,
    timeStamp: null,
    device_id: null,
    data: {
      device_name: '',
      details: '',
      event_id: null,
    },
  };

  setLog = (log) => {
    this.logs.unshift({
      device_id: log.device_id,
      timestamp: log.timestamp,
      data: {
        device_name: log.data.device_name,
        details: log.data.details,
        event_id: log.data.event_id,
      },
    });
  };

  setNotification = (notification) => {
    this.notifications.unshift({
      device_id: notification.device_id,
      timestamp: notification.timestamp,
      data: {
        device_name: notification.data.device_name,
        details: notification.data.details,
        event_id: notification.data.event_id,
      },
    });

    showNotificationToster(
      notification.data.device_name,
      notification.data.details,
      'warn',
      notification.timestamp,
      'notifications-custom'
    );
  };

  setNotificationsList = (list) => {
    this.notifications = list;
  };

  subscribe = () => {
    this.rootStore.socket.subscribe('notification_list', ({ data }) =>
      this.setNotificationsList(data)
    );
    this.rootStore.socket.subscribe('log_message', (log) => this.setLog(log));
    this.rootStore.socket.subscribe('notification_message', (notification) =>
      this.setNotification(notification)
    );
  };

  getInitialData = () => {
    this.rootStore.socket.send({ activity: 'action_get_notifications' });
  };

  setSelectedNotification = (id) => {
    this.selectedNotification = this.notifications.find(
      (log) => log.data.event_id === id
    );
  };

  clearLogs = () => {
    this.logs = [];
  };

  deleteNotification = (eventId) => {
    this.setSelectedNotificationRead(eventId);
    this.notifications = this.notifications.filter(
      (el) => el.data.event_id !== eventId
    );
  };

  deleteAllNotifications = () => {
    const storedNotifications = this.notifications;
    this.notifications = [];
    storedNotifications.filter((el) =>
      this.setSelectedNotificationRead(el.data.event_id)
    );
  };

  setSelectedNotificationRead = (id) => {
    this.rootStore.socket.send({
      activity: 'action_notification_read',
      event_id: id,
    });
  };
}

export default decorate(LogsStore, {
  logs: observable,
  selectedNotification: observable,
  notifications: observable,
  setSelectedNotification: action,
  setNotificationsList: action,
  getInitialData: action,
  setLog: action,
  setNotification: action,
  clearLogs: action,
  deleteNotification: action,
  deleteAllNotifications: action,
});
