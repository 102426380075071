import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from '../../store/RootStateContext';

import './styles.less';

const INFO_BLOCK = ['callSettingsInfo.redirected', 'callSettingsInfo.rollover'];

const CallSettingsInfo = () => {
  const { callsStats } = useRootStore();

  return (
    <div className="call-settins-info-wrapper">
      {INFO_BLOCK.map((el, idx) => {
        const value = Object.values(callsStats.callSettingInfo)[idx] ? (
          <Link to="/app/settings">
            {Object.values(callsStats.callSettingInfo)[idx]}
          </Link>
        ) : (
          '-'
        );
        return (
          <div className="call-info" key={el}>
            <FormattedMessage id={el} />
            &nbsp;
            <div
              className={classnames({
                set: value !== '-',
                unset: value === '-',
              })}
            >
              {value}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(CallSettingsInfo);
