import { observable, decorate, action } from 'mobx';

class CallsStats {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  callSettingInfo = {
    redirect: null,
    rollover: null,
    vu_number: null,
    primary_security_name: null,
  };

  setCallStats = (data) => {
    const rawData = data;
    delete rawData.type;
    this.callSettingInfo = rawData;
  };

  subscribe = () => {
    this.rootStore.socket.subscribe('calls_stats', (data) =>
      this.setCallStats(data)
    );
  };

  getInitialCallsStats = () => {
    this.rootStore.socket.send({ activity: 'action_calls_stats' });
  };
}

export default decorate(CallsStats, {
  callSettingInfo: observable,
  setCallStats: action,
  subscribe: action,
  getInitialCallsStats: action,
});
