import React, { useState } from 'react';
import { Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useRootStore } from '../../../store/RootStateContext';
import '../styles.less';

const FetchButton = ({ children, id, status, title }) => {
  const { controlTableStore } = useRootStore();
  const [showSpinner, setShowSpinner] = useState(false);
  const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const isDisabled = status === 'disabled';

  const clickHandler = () => {
    if (!isDisabled) {
      controlTableStore.triggerAction(title, id, status);
      setShowSpinner(true);
      setTimeout(() => setShowSpinner(false), 1500);
    }
  };

  const classes = classnames('table-item', {
    'negative-bgc':
      (title === 'DISABLE_VISITOR' || title === 'LOCKDOWN') &&
      status === 'activated',
    'positive-bgc':
      (title === 'UNLOCK' || title === 'OPEN_DOOR') && status === 'activated',
    'disabled-item': status === 'disabled',
  });

  return (
    <Space size="large">
      <Spin spinning={showSpinner} indicator={spinnerIcon}>
        <div className={classes} onClick={clickHandler}>
          {children}
        </div>
      </Spin>
    </Space>
  );
};

FetchButton.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default observer(FetchButton);
