import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'antd';
import { Howl } from 'howler';
import {
  UnlockOutlined,
  LockOutlined,
  PhoneOutlined,
  AudioOutlined,
  AudioMutedOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import scrollIntoView from 'scroll-into-view-if-needed';
import { FormattedMessage } from 'react-intl';

import { useRootStore } from '../../store/RootStateContext';
import formatTime from '../../services/formatTime';
import incomingSfx from '../../assets/sounds/incoming_call_ringtone.mp3';

import './styles.less';

const MAX_MOBILE_SCREEN_WIDTH = 813;

const CallPlayer = () => {
  const { tokBoxStore } = useRootStore();

  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [mute, setMute] = useState(false);
  const [isOpenDoorButtonDisabled, setIsOpenDoorButtonDisabled] = useState(
    false
  );

  const ref = useRef(null);

  const isMobile = window.innerWidth < MAX_MOBILE_SCREEN_WIDTH;

  const ringtone = new Howl({
    src: [incomingSfx],
    autoplay: true,
    loop: true,
    volume: 0.5,
  });

  const endRinging = () => {
    ringtone.stop();
  };

  const { isStreamActive } = tokBoxStore;

  useEffect(() => {
    if (isMobile) {
      scrollIntoView(ref.current, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [ref, isMobile]);

  useEffect(() => {
    if (tokBoxStore.isIncoming) {
      ringtone.play();
    }

    if (!tokBoxStore.isIncoming) {
      ringtone.stop();
    }
    return () => {
      ringtone.stop();
    };
  }, [tokBoxStore.isIncoming, ringtone]);

  useEffect(() => {
    if (isRunning && isStreamActive) {
      const id = window.setInterval(() => {
        setSeconds((sec) => sec + 1);
      }, 1000);
      return () => window.clearInterval(id);
    }
    return undefined;
  }, [isRunning, isStreamActive]);

  const onEndCall = () => {
    if (tokBoxStore.currentConnection.isIncomingCall) {
      tokBoxStore.rejectIncomingCall(tokBoxStore.currentConnection.currentVuId);
    } else {
      tokBoxStore.closeCall(tokBoxStore.currentConnection.currentVuId);
    }

    endRinging();
    setIsRunning(false);
  };

  const onEndIncomingCall = () => {
    tokBoxStore.rejectIncomingCall();
    endRinging();
    setIsRunning(false);
  };

  const onSetDoorOpen = () => {
    tokBoxStore.callOpenDoor(
      tokBoxStore.currentConnection.currentVuId,
      tokBoxStore.currentConnection.isIncomingCall
    );
    endRinging();
    setIsOpenDoorButtonDisabled(true);
    setTimeout(() => {
      setIsOpenDoorButtonDisabled(false);
    }, 7000);
    if (tokBoxStore.currentConnection.isIncomingCall) {
      setIsRunning(false);
    }
  };
  const onIncomingCallSetDoorOpen = () => {
    tokBoxStore.callOpenDoor(tokBoxStore.incomingCallData.device_id, true);
    endRinging();
    setIsRunning(false);
  };

  const onAnswerIncomingCall = () => {
    tokBoxStore.acceptIncomingCall();
    endRinging();
    setIsRunning(false);
  };

  const toggleMute = () => {
    tokBoxStore.publisher.publishAudio(mute);
    setMute(!mute);
  };

  const outgoingInterface = isStreamActive ? (
    <>
      <div className="players">
        <div id="publisher" className="publisher-wrapper" />
        <div id="subscriber" className="subscriber-wrapper" />
      </div>
      <div className="call-control-wrapper">
        <div className="mute-btn">
          <Button
            size="large"
            shape="circle"
            icon={
              !mute ? (
                <AudioOutlined />
              ) : (
                <AudioMutedOutlined className="muted" />
              )
            }
            onClick={toggleMute}
          />
        </div>
        {tokBoxStore.currentConnection && (
          <div className="call-device-name">
            {tokBoxStore.currentConnection.device_name}
          </div>
        )}
        <div className="controls-wrapper">
          <div className="control-btn-wrapper">
            {tokBoxStore.currentConnection.isIncomingCall && (
              <div className="timer-wrapper">{formatTime(seconds)}</div>
            )}
            {!tokBoxStore.currentConnection.isIncomingCall && (
              <div className="timer-wrapper">{formatTime(seconds)}</div>
            )}
            <div className="control-btn">
              <div>
                <Button
                  size="large"
                  shape="circle"
                  icon={<UnlockOutlined />}
                  onClick={onSetDoorOpen}
                  disabled={isOpenDoorButtonDisabled}
                />
                <span>
                  <FormattedMessage id="callPlayer.openDoor" />
                </span>
              </div>

              <div>
                <Button
                  size="large"
                  shape="circle"
                  icon={<LockOutlined />}
                  onClick={onEndCall}
                />
                <span>
                  {tokBoxStore.currentConnection.isIncomingCall ? (
                    <FormattedMessage id="callPlayer.denyAccess" />
                  ) : (
                    <FormattedMessage id="callPlayer.endCall" />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;

  const reconectingScreen = tokBoxStore.isReconnecting ? (
    <div className="reconnecting-screen">
      <span>
        <FormattedMessage id="callPlayer.connectionLost" />
      </span>
    </div>
  ) : null;

  const incomingInterface = tokBoxStore.isIncoming ? (
    <div
      className="incoming-screen"
      style={{
        backgroundImage: `url(${tokBoxStore.incomingCallImage})`,
      }}
    >
      <div className="incoming-call-device-name">
        {tokBoxStore.incomingCallData.data.device_name}
      </div>
      <div className="incoming-btn-section">
        <div className="incoming-btn">
          <Button
            size="large"
            shape="circle"
            icon={<UnlockOutlined />}
            onClick={onIncomingCallSetDoorOpen}
          />
          <span>
            <FormattedMessage id="callPlayer.openDoor" />
          </span>
        </div>
        <div className="incoming-btn answer-btn">
          <Button
            size="large"
            shape="circle"
            icon={<PhoneOutlined />}
            onClick={onAnswerIncomingCall}
          />
          <span>
            <FormattedMessage id="callPlayer.answerCall" />
          </span>
        </div>
        <div className="incoming-btn">
          <Button
            size="large"
            shape="circle"
            icon={<LockOutlined />}
            onClick={onEndIncomingCall}
          />
          <span>
            <FormattedMessage id="callPlayer.denyAccess" />
          </span>
        </div>
      </div>
    </div>
  ) : null;

  const classes = classnames('player-wrapper', {
    'hide-player': tokBoxStore.isChangeSession || tokBoxStore.isReconnecting,
  });

  return (
    <>
      <div className={classes} ref={ref}>
        {outgoingInterface}
        {incomingInterface}
      </div>
      {reconectingScreen}
    </>
  );
};

export default observer(CallPlayer);
