import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import { HeaderSection } from '../../components';

import './styles.less';

const MainLayout = ({ children }) => {
  return (
    <Layout className="main-layout-wrapper">
      <Layout className="site-layout">
        <HeaderSection />
        {children}
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
